import React, { Component } from "react"
import { Link } from "gatsby"
import ReactDOMServer from 'react-dom/server'
import he from 'he'
import { nestMenu } from '../utils/menu'

export default class SideNav extends Component {
  render() {
    let currentPath = this.props.currentPath
    let nav = nestMenu(this.props.menuLinks, currentPath)
    let firstRoot = true
    let navBarItems = nav.map(function(element, i){
      if(element.children.length>0){
        let isActive = false
        let subActive = false
        let links = null
        let items = element.children.map(function(item, j){
          if(item.url === currentPath){
            isActive = true
          }
          let subItems = item.children.map(function(subItem, k){
            let classes = ' ' + subItem.cssClasses.join(' ')
            let label = he.decode(ReactDOMServer.renderToStaticMarkup(subItem.label))
            return(<li key={k}><Link to={subItem.url} className={(subItem.active ? 'is-active' : '')+classes} dangerouslySetInnerHTML={{__html: label}} /></li>)
          })
          if(item.children.length > 0){
            subItems = <ul>{subItems}</ul>
          }
          let classes = ' ' + item.cssClasses.join(' ')
          let label = he.decode(ReactDOMServer.renderToStaticMarkup(item.label))
          return(<li key={j}><Link to={item.url} className={((item.active) ? 'is-active' : '')+classes} dangerouslySetInnerHTML={{__html: label}} />{subItems}</li>)
        })
        if(element.active && firstRoot){
          firstRoot = false
          return(<ul key={i}>{items}</ul>)
        }else{
          // return(<ul key={i}>{items}</ul>)
          return(null)
        }
      }else{
        return(null)
      }
    })
    return (
      <nav className='navbar side-nav'>
        {navBarItems}
      </nav>
    )
  }
}
