import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SideNav from '../components/sidenav'
import ContentFormatter from '../components/content-formatter'

class Page extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    let post = this.props.data.wpPage
    let seo = post.seo
    let title = post.title
    let myContent = null
    console.log(this.props)
    return (
      <Layout location={this.props.location}>
        <SEO title={title} image={seo?.opengraphImage?.sourceUrl} />
        <div className="columns content">
          <ContentFormatter content={post?.content} />
        </div>
      </Layout>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(id: { eq: $id }) {
      title
      content
      template {
        templateName
      }
      seo {
        metaDesc
        opengraphImage {
          sourceUrl
          mediaItemUrl
        }
        metaKeywords
        opengraphDescription
      }
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
    allWpMenu(filter: {locations: {eq: HEADER_MENU}}) {
      nodes {
        menuItems {
          nodes {
            databaseId
            label
            title
            url
            parentDatabaseId
            cssClasses
          }
        }
      }
    }
  }
`
